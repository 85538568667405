<template>
  <div class="set-point">
    <!-- 测点信息 -->
    <el-form :model="form1" :inline="true" :rules="rule1" ref="form1">
      <p class="colorTable">测点信息</p>
      <el-form-item class="long-input" label="测点编号" prop="code">
        <el-input v-model="form1.code" placeholder="请输入测点编号" clearable :disabled="disabled1" />
      </el-form-item>
      <el-form-item label="监测类型" prop="typeGroupId">
        <el-select v-model="form1.typeGroupId" placeholder="请选择" @change="getTargetOptions" :disabled="disabled1">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="监测指标" prop="typeId">
        <el-select v-model="form1.typeId" placeholder="请选择" @change="getPoints" :disabled="disabled1">
          <el-option v-for="item in targetOptions" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="位置描述" class="long-input" prop="site">
        <el-input v-model="form1.site" placeholder="请输入" clearable :disabled="disabled1" />
      </el-form-item>
      <el-form-item label="采样时间间隔" prop="frequency"> <el-input v-model="form1.frequency" placeholder="请输入" min="1" clearable :disabled="disabled1" />秒 </el-form-item>
      <el-form-item label="数据类型" prop="staticDynamicSelected">
        <el-select v-model="form1.staticDynamicSelected" placeholder="请选择" @change="selectDataType" :disabled="disabled1">
          <el-option v-for="item in dataTypeOpitons" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="传感器型号" prop="model">
        <el-input v-model="form1.model" placeholder="请输入" clearable :disabled="disabled1" />
      </el-form-item>
      <el-form-item label="系数" prop="coefficient">
        <el-input v-model="form1.coefficient" placeholder="请输入" clearable :disabled="disabled1" />
      </el-form-item>
      <!-- <el-form-item label="单位" prop="unit">
        <el-input v-model="form1.unit" placeholder="请输入" clearable :disabled="disabled1" />
      </el-form-item>
      <el-form-item label="精度" prop="precisions">
        <el-input v-model="form1.precisions" placeholder="请输入" clearable :disabled="disabled1" />
      </el-form-item> -->
      <el-form-item label="通讯地址" class="long-input" prop="serialNumber">
        <el-input v-model.number="form1.serialNumber" placeholder="请输入" clearable :disabled="disabled1" />
      </el-form-item>
      <!-- <el-form-item label="自选状态" prop="selectedRel">
        <el-select v-model="form1.selectedRel" placeholder="请选择" :disabled="disabled1">
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="衍生指标">
        <el-select v-model="form1.derive" placeholder="请选择" @change="selectDeriveTarget">
          <el-option label="是" :value="1" />
          <el-option label="否" :value="0" />
        </el-select>
      </el-form-item> -->
    </el-form>
    <!-- 衍生指标关联计算 -->
    <!-- <el-form
      v-show="form1.derive==1"
      class="derive"
      :model="deriveForm"
      :inline="true"
      :rules="ruleDerive"
      ref="deriveForm">
      <p>衍生指标关联计算</p>
      <el-form-item label="计算公式" required>
        <el-select v-model="deriveForm.equation" placeholder="请选择" @change="getComputeId">
          <el-option
            v-for="item in computeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="关联测点一" prop="firstTargetId">
        <el-select
          v-model="deriveForm.firstTargetId"
          placeholder="请选择测点"
          @change="selectPoint(deriveForm.firstTargetId,'one')"
        >
          <el-option
            v-for="item in point1Options"
            :key="item.id"
            :value="item.id"
            :label="item.code"
            :disabled="item.disabled"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="关联测点二">
        <el-select
          v-model="deriveForm.secondTargetId"
          placeholder="请选择测点"
          @change="selectPoint(deriveForm.secondTargetId,'two')"
        >
          <el-option
            v-for="item in point2Options"
            :key="item.id"
            :label="item.code"
            :value="item.id"
            :disabled="item.disabled"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="关联测点三">
        <el-select
          v-model="deriveForm.thirdTargetId"
          placeholder="请选择测点"
          @change="selectPoint(deriveForm.thirdTargetId,'three')"
        >
          <el-option
            v-for="item in point3Options"
            :key="item.id"
            :label="item.code"
            :value="item.id"
            :disabled="item.disabled"
          />
        </el-select>
      </el-form-item>
    </el-form> -->
    <!-- 数据预处理设置 -->
    <div class="multi-form">
      <!-- <p>数据预处理设置</p>
      <section>
        <span class="title">缺失数据补齐</span>
        <el-form :model="form2" :inline="true" ref="form2" :rules="rule2">
          <el-form-item label="是否缺失数据补齐" required>
            <el-select v-model="form2.patchSelected" placeholder="请选择" @change="selectPlolish">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="form2.patchSelected==1" label="处理方法">
            <el-select @change="selectPatchMethod" v-model="form2.patchMethod" placeholder="请选择">
              <el-option
                v-for="item in preMethodOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form2.patchSelected==1" label="时段长度" prop="patchDuration">
            <el-input v-model="form2.patchDuration" placeholder="请输入" clearable />
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section>
      <section>
        <span class="title">异常数据去除</span>
        <el-form :model="form3" :inline="true" ref="form3" :rules="rule3">
          <el-form-item label="是否去除异常值" prop="outlierSelected" required>
            <el-select
              v-model="form3.outlierSelected"
              placeholder="请选择"
              :disabled="form1.staticDynamicSelected==2"
              @change="selectWipeOff">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="form3.outlierSelected==1" label="时段长度" prop="outlierDuration">
            <el-input v-model="form3.outlierDuration" placeholder="请输入" clearable />
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section>
      <section class="denosing-form">
        <span class="title">消噪处理</span>
        <el-form :model="form4" :inline="true" ref="form4" :rules="rule4">
          <el-form-item label="是否消噪处理" required>
            <el-select v-model="form4.denoisingSelected" placeholder="请选择" @change="selectDenosing">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="form4.denoisingSelected==1" label="处理方法" prop="selectedArr">
            <el-checkbox-group v-model="form4.selectedArr">
              <el-checkbox
                name="type"
                @change="selectMethods(item.id)"
                v-for="item in methodOptions"
                :key="item.id"
                :label="item.id"
                :value="item.id">
                {{item.name}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="窗宽度" v-if="form4.denoisingBoxSelected==1" prop="denoisingBoxWidth">
            <el-input v-model.number="form4.denoisingBoxWidth" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item
            label="低通滤波频率阈值"
            v-if="form4.denoisingLowpassSelected==1"
            prop="denoisingLowpassThreshold">
            <el-input v-model="form4.denoisingLowpassThreshold" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item
            label="陷波滤波频率阈值"
            v-if="form4.denoisingNotchSelected==1"
            prop="denoisingNotchThreshold">
            <el-input v-model="form4.denoisingNotchThreshold" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item v-show="form4.denoisingSelected==1" label="时段长度" prop="denoisingDuration">
            <el-input v-model="form4.denoisingDuration" placeholder="请输入" clearable />
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section> -->
      <!-- 数据二次处理 -->
      <!-- 基线校正 -->
      <p>数据二次处理设置</p>
      <section class="rehandle">
        <span class="title">基线校正</span>
        <el-form :model="form5" :inline="true" ref="form5" :rules="rule5">
          <el-form-item label="是否进行基线校正处理" required>
            <!-- 非静态的时候做基线校正 -->
            <el-select v-model="form5.baselineSelected" placeholder="请选择" :disabled="form1.staticDynamicSelected == 1">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <el-form-item label="类别参数" v-if="form5.baselineSelected == 1" prop="baselineType">
            <el-select v-model="form5.baselineType" placeholder="请选择">
              <el-option label="振荡加突变" :value="1" />
              <el-option label="低频扰动加非线性趋势" :value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="时间间隔" v-if="form5.baselineSelected == 1" prop="baselinePeriod">
            <el-input v-model.number="form5.baselinePeriod" placeholder="请输入" clearable />
            <span class="unit">秒</span>
          </el-form-item>
          <el-form-item label="时段长度" v-if="form5.baselineSelected == 1" prop="baselineDuration">
            <el-input v-model="form5.baselineDuration" placeholder="请输入" clearable />
            <span class="unit">分钟</span>
          </el-form-item>
          <el-form-item label="规则系数" v-if="form5.baselineSelected == 1 && form5.baselineType == 1" prop="baselineRatio">
            <el-input v-model="form5.baselineRatio" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="截止频率" v-if="form5.baselineSelected == 1 && form5.baselineType == 2" prop="baselineCutoffFrequency">
            <el-input v-model="form5.baselineCutoffFrequency" placeholder="请输入" clearable />
            <span class="unit">Hz</span>
          </el-form-item>
        </el-form>
      </section>
      <!-- 动静分离 -->
      <section class="rehandle">
        <span class="title">动静分离</span>
        <el-form :model="form6" :inline="true" ref="form6" :rules="rule6">
          <el-form-item label="是否进行动静分离处理" required>
            <!-- 只有动态时做基线校正 -->
            <el-select v-model="form6.staticDynamicSeparationSelected" :disabled="form1.staticDynamicSelected != 2">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
          <!-- 数据类型为振动响应时，在基线校正不启用的情况下，动静分离的时间间隔应该手动设置。在基线校正启用的情况下，动静分离的时间间隔和基线校正的时间间隔联动 -->
          <el-form-item label="时间间隔" v-show="form6.staticDynamicSeparationSelected == 1">
            <el-input v-model="form5.baselinePeriod" disabled />
            <span class="unit">秒</span>
          </el-form-item>
          <el-form-item label="时段长度" v-if="form6.staticDynamicSeparationSelected == 1" prop="staticDynamicDuration">
            <el-input v-model="form6.staticDynamicDuration" placeholder="请输入" clearable />
            <span class="unit">分钟</span>
          </el-form-item>
          <el-form-item label="截止频率" v-if="form6.staticDynamicSeparationSelected == 1" prop="staticDynamicCutoffFrequency">
            <el-input v-model="form6.staticDynamicCutoffFrequency" placeholder="请输入" clearable />
            <span class="unit">Hz</span>
          </el-form-item>
        </el-form>
      </section>
    </div>
    <!--  数据分析处理设置 -->
    <div class="multi-form analyse">
      <p>数据分析处理设置</p>
      <!-- 时域统计 -->
      <section>
        <span class="title">时域统计</span>
        <el-form :model="form7" :inline="true" ref="form7" :rules="rule7">
          <!-- 数据类型为静态时，展示时间间隔和时段长度-->
          <template v-if="form1.staticDynamicSelected == 1">
            <el-form-item label="时间间隔" prop="timePeriod">
              <el-input v-model.number="form7.timePeriod" placeholder="请输入" clearable />
              <span class="unit">秒</span>
            </el-form-item>
            <el-form-item label="时段长度" class="time_length" prop="timeDuration">
              <el-input v-model.number="form7.timeDuration" />
              <span class="unit">分钟</span>
            </el-form-item>
          </template>
          <!-- 数据类型为非静态时，只展示时间间隔（默认为基线校正的时间间隔）,禁止修改。 -->
          <el-form-item v-else label="时间间隔">
            <el-input v-model="form5.baselinePeriod" disabled />
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section>
      <!-- 准静态时域统计(动态效应且动静分离选是时显示) -->
      <section v-if="form1.staticDynamicSelected == 2 && form6.staticDynamicSeparationSelected == 1">
        <span class="title">准静态时域统计</span>
        <el-form :inline="true">
          <!-- 只展示时间间隔（默认为基线校正的时间间隔）,禁止修改。 -->
          <el-form-item label="时间间隔">
            <el-input v-model="form5.baselinePeriod" disabled />
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section>
      <!-- 频域分析 -->
      <section>
        <span class="title">频域分析</span>
        <el-form :model="form9" :inline="true" ref="form9" :rules="rule9">
          <!-- 数据类型是静态时，时段长度显示为不分析（禁止修改） -->
          <el-form-item label="时段长度" prop="frequencyDuration">
            <el-select v-model="form9.frequencyDuration" placeholder="请选择" @change="selectTimeFrame(form9.frequencyDuration, 'form9')" :disabled="form1.staticDynamicSelected == 1">
              <el-option v-for="item in timeOptions" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="form9.frequencyDuration == 0" prop="frequencyDurationOther">
            <el-input v-model="form9.frequencyDurationOther" placeholder="请输入" clearable />
            <span class="unit">小时</span>
          </el-form-item>
          <!-- 数据类型为非静态时（form1.staticDynamicSelected!=1），时间间隔默认展示基线校正的时间间隔 -->
          <el-form-item v-if="form9.frequencyDuration != 99 && form1.staticDynamicSelected != 1" label="时间间隔" class="interval">
            <el-input v-model="form5.baselinePeriod" disabled />
            <span class="unit">秒</span>
          </el-form-item>
          <el-form-item
            v-if="form9.frequencyDuration != 99 && form1.staticDynamicSelected != 1"
            label="窗口长度"
            class="long-input"
            prop="frequencyWindow"
            :rules="[
              { required: true, message: '请输入窗口长度', trigger: 'blur' },
              { min: 1, type: 'number', message: '窗口长度必须大于0', trigger: 'blur' }
            ]"
          >
            <el-input v-model.number="form9.frequencyWindow" placeholder="请输入" min="1" clearable />
          </el-form-item>
          <el-form-item
            v-if="form9.frequencyDuration != 99 && form1.staticDynamicSelected != 1"
            label="平滑点数"
            class="long-input"
            prop="frequencySmooth"
            :rules="[
              { required: true, message: '请输入平滑点数', trigger: 'blur' },
              { min: 1, type: 'number', message: '平滑点数必须大于0', trigger: 'blur' }
            ]"
          >
            <el-input v-model.number="form9.frequencySmooth" placeholder="请输入" min="1" clearable />
          </el-form-item>
        </el-form>
      </section>
      <!-- 温度效应提取 -->
      <section>
        <span class="title">温度效应提取</span>
        <el-form :model="form8" :inline="true" ref="form8" :rules="rule8">
          <el-form-item label="时段长度" prop="tempEffectDuration">
            <el-select v-model="form8.tempEffectDuration" placeholder="请选择">
              <el-option v-for="item in timeOptions2" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <template v-if="form8.tempEffectDuration != 99">
            <el-form-item label="分段时长" prop="tempEffectAnalyseDuration">
              <el-input v-model.number="form8.tempEffectAnalyseDuration" placeholder="请输入" clearable />
              <span class="unit">分钟</span>
            </el-form-item>
            <el-form-item label="延迟时间" class="interval">
              <el-input v-model="form5.baselinePeriod" disabled />
              <span class="unit">秒</span>
            </el-form-item>
            <!-- 数据类型为非静态时，显示时间间隔和存储间隔 -->
            <el-form-item v-if="form1.staticDynamicSelected != 1" label="时间间隔" prop="tempEffectIsolation">
              <el-input v-model="form8.tempEffectIsolation" placeholder="请输入" clearable />
              <span class="unit">小时</span>
            </el-form-item>
            <el-form-item v-if="form1.staticDynamicSelected != 1" label="存储间隔" prop="tempEffectInterval">
              <el-input v-model.number="form8.tempEffectInterval" placeholder="请输入" clearable />
              <span class="unit">分钟</span>
            </el-form-item>
          </template>
        </el-form>
      </section>
      <!-- 模态分析测点选择 -->
      <section>
        <span class="title">模态分析测点选择</span>
        <el-form :model="formData" :inline="true" :rules="rule11" ref="formData">
          <!-- 数据类型为静态时，直接选否，且禁止选择 -->
          <!-- 数据类型为非静态时，可以选择 -->
          <el-form-item label="是否启动">
            <el-select v-model="formData.modalSelected" placeholder="请选择" :disabled="form1.staticDynamicSelected == 1">
              <el-option label="是" :value="1" />
              <el-option label="否" :value="0" />
            </el-select>
          </el-form-item>
        </el-form>
      </section>
      <!-- 趋势分析 -->
      <section>
        <span class="title">趋势分析</span>
        <el-form :model="trendForm" :inline="true" ref="trendForm" :rules="trendRule" class="trendForm">
          <el-form-item label="分析方法" prop="trendType" required>
            <el-select v-model="trendForm.trendType" placeholder="请选择">
              <el-option v-for="item in timeOptions3" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <template v-if="trendForm.trendType != 0">
            <el-form-item label="阶数" prop="trendOrder">
              <el-input v-model.number="trendForm.trendOrder" placeholder="请输入" clearable />
            </el-form-item>
            <el-form-item label="分析时段" prop="trendDuration">
              <el-input v-model="trendForm.trendDuration" placeholder="请输入" clearable />
              <span class="unit">小时</span>
            </el-form-item>
            <el-form-item label="时间间隔" prop="trendPeriod">
              <el-input v-model="trendForm.trendPeriod" clearable />
              <span class="unit">小时</span>
            </el-form-item>
            <el-form-item label="间隔点数" prop="trendNPoint">
              <el-input v-model.number="trendForm.trendNPoint" placeholder="请输入" clearable />
            </el-form-item>
            <el-form-item label="存储间隔" prop="trendInterval">
              <el-input v-model="trendForm.trendInterval" placeholder="请输入" clearable />
              <span class="unit">小时</span>
            </el-form-item>
          </template>
        </el-form>
      </section>
      <!-- 效应预测 -->
      <section>
        <span class="title">效应预测</span>
        <el-form :model="effectionForm" :inline="true" ref="effectionForm" style="position: relative">
          <el-form-item label="是否启动" prop="startFlag">
            <el-select v-model="effectionForm.startFlag" placeholder="请选择">
              <el-option v-for="item in effectionOption" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <template v-if="effectionForm.startFlag != 0">
            <div class="time-div">
              <el-form-item prop="beginTime">
                <el-date-picker disabled type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" v-model="effectionForm.beginTime"> </el-date-picker>
              </el-form-item>
              <span class="line">-</span>
              <el-form-item prop="endTime">
                <el-date-picker @change="handleEndTimeChange" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" v-model="effectionForm.endTime"> </el-date-picker>
              </el-form-item>
            </div>
            <el-form-item label="状态" prop="status">
              {{ formatStatus(effectionForm.status) }}
            </el-form-item>
            <!-- <el-button class="exercise-model" @click="clickModelBtn">训练模型</el-button> -->
          </template>
        </el-form>
      </section>
    </div>
    <!-- 单指标阈值设置 -->
    <!-- <div class="multi-form">
      <p>单指标阈值设置</p>
      <section>
        <p>时长设置</p>
        <el-form class="set-time" :model="form10" :inline="true">
          <el-form-item label="是否预警" required>
            <el-select v-model="form10.duration" placeholder="请选择">
              <el-option
                v-for="item in durationOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
            </el-select>
          </el-form-item>
          <el-form-item
            label="预警分析时长"
            v-show="form10.duration==1"
            :rules="{required: true, message: '请输入预警分析时长', trigger: 'blur'}">
            <el-input v-if="form1.staticDynamicSelected==1" :value="form7.timePeriod" disabled />
            <el-input v-else :value="form5.baselinePeriod" disabled />
            <span class="unit">秒</span>
          </el-form-item>
        </el-form>
      </section>
      <section class="set-threshold" v-if="form10.duration==1">
        <div class="title">阈值设置</div>
        <div class="form-list">
          <div class="form-title">
            <span>正向阈值</span>
            <span>负向阈值</span>
          </div>
          <el-form :model="form10" :inline="true" ref="form10" :rules="rule10">
            <div class="limit-value">
              <el-form-item label="黄色预警" prop="clower">
                <el-input v-model.number="form10.clower" placeholder="输入阈值下限" />
              </el-form-item>
              <el-form-item label="≤ 监测值 &lt;" prop="cupper">
                <el-input
                  @input="getForwardData"
                  v-model.number="form10.cupper"
                  placeholder="输入阈值上限"
                />
              </el-form-item>
              <el-form-item label="橙色预警" required>
                <el-input v-model.number="form10.blower" disabled />
              </el-form-item>
              <el-form-item label="≤ 监测值 &lt;" prop="bupper">
                <el-input
                  @input="getForwardData"
                  v-model.number="form10.bupper"
                  placeholder="输入阈值上限"
                />
              </el-form-item>
              <el-form-item label="红色预警">
                <el-input v-model.number="form10.alower" disabled />
              </el-form-item>
              <el-form-item label="≤ 监测值" />
            </div>
            <div class="limit-value">
              <el-form-item label="黄色预警" prop="flower">
                <el-input
                  @input="getNegativeData"
                  v-model.number="form10.flower"
                  placeholder="输入阈值下限"
                />
              </el-form-item>
              <el-form-item label="&lt; 监测值 ≤" prop="fupper">
                <el-input v-model.number="form10.fupper" placeholder="输入阈值上限" />
              </el-form-item>
              <el-form-item label="橙色预警" prop="elower">
                <el-input @input="getNegativeData" v-model.number="form10.elower" />
              </el-form-item>
              <el-form-item label="&lt; 监测值 ≤ ">
                <el-input v-model.number="form10.eupper" placeholder="输入阈值上限" disabled />
              </el-form-item>
              <el-form-item label="红色预警" required />
              <el-form-item label="监测值 ≤" class="red-warning">
                <el-input v-model.number="form10.dupper" disabled />
              </el-form-item>
            </div>
          </el-form>
        </div>
      </section>
    </div> -->
    <div class="btns box around mB20">
      <!-- <el-button @click="handleSave">保存</el-button>
      <el-button @click="$router.go(-1)">返回</el-button> -->
      <div class="box center mB20">
        <div class="btnSelect1 mR20 pointer" @click="back">返回</div>
        <div class="btnSelect2 pointer" @click="handleSave">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import reportVue from "../report/report.vue";
import moment from "moment";
export default {
  data() {
    var checkedSerialNumber = (rule, value, callback) => {
      if (value) {
        if (!Number(value)) callback("请输入数字");
      } else {
        callback();
      }
    };
    var checkedNumber = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("请输入大于0的数字");
      } else if (!(Number(value) > -1)) {
        callback("请输入数字");
      } else {
        callback();
      }
    };
    var checkedTrendDuration = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("请输入大于0的数字");
      } else if (!(Number(value) > -1)) {
        callback("请输入数字");
      } else if (Number(value) > 24) {
        callback("不能超过24小时");
      } else {
        callback();
      }
    };
    var checkedMinNumb = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("必须大于0");
      } else if (!Number(value)) {
        callback("请输入数字");
      } else if (Number(value) > 10) {
        callback("必须小于10");
      } else if (parseInt(this.formData.max) && Number(value) > parseInt(this.formData.max)) {
        callback("必须小于最大阶乘");
      } else callback();
    };
    var checkedMaxNumb = (rule, value, callback) => {
      if (value === "") {
        callback("请输入");
      } else if (value == 0) {
        callback("必须大于0");
      } else if (!Number(value)) {
        callback("请输入数字");
      } else if (Number(value) > 10) {
        callback("不能大于10");
      } else if (parseInt(this.formData.min) && Number(value) < parseInt(this.formData.min)) {
        callback("必须大于最小阶乘");
      } else callback();
    };
    var checkedForwardLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (parseInt(value) > parseInt(this.form10.cupper)) {
        callback("下限不能大于上限");
      } else {
        callback();
      }
    };
    var checkedForwardYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (parseInt(value) < parseInt(this.form10.clower)) {
        callback("上限不能小于下限");
      } else {
        callback();
      }
    };
    var checkedForwardOrangeUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (parseInt(value) < parseInt(this.form10.cupper)) {
        callback("上限不能小于下限");
      } else {
        callback();
      }
    };
    var checkedNegativeYellowLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值下限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (!(parseInt(value) < parseInt(this.form10.fupper))) {
        callback("下限必须小于上限");
      } else {
        callback();
      }
    };
    var checkedNegativeYellowUpperLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (!(parseInt(value) > parseInt(this.form10.flower))) {
        callback("上限必须大于下限");
      } else {
        callback();
      }
    };
    var checkedNegativeOrangeLowerLimit = (rule, value, callback) => {
      if (value === "") {
        callback("请输入阈值上限");
      } else if (!Number(value)) {
        callback("阈值必须为数字");
      } else if (!(parseInt(value) < parseInt(this.form10.flower))) {
        callback("下限必须小于上限");
      } else {
        callback();
      }
    };
    return {
      effectionForm: {
        startFlag: "0",
        beginTime: "",
        endTime: "",
        status: ""
      },
      effectionOption: [
        {
          id: "0",
          name: "否"
        },
        {
          id: "1",
          name: "是"
        }
      ],
      arr: [],
      disabled1: true,
      isFirstEdit: 0, // 标记第一次进入编辑页面，1-->为第一次
      targetId: "",
      projectId: "",
      patchMethod: "",
      trendForm: { trendType: 0 },
      form1: { derive: 0, staticDynamicSelected: 1 },
      deriveForm: {},
      form2: { patchSelected: 0 },
      form3: { outlierSelected: 0 },
      form4: {
        selectedArr: [],
        denoisingSelected: 0,
        denoisingBoxSelected: 0,
        denoisingNotchSelected: 0,
        denoisingLowpassSelected: 0,
        denoisingLowpassThreshold: "",
        denoisingNotchThreshold: "",
        denoisingDuration: "",
        denoisingBoxWidth: ""
      },
      form5: { baselineSelected: 0, baselineType: 1 },
      form6: { staticDynamicSeparationSelected: 0 },
      form7: { timePeriod: 300, timeDuration: 99 },
      form8: { tempEffectDuration: 99 },
      form9: { frequencyDuration: 99 },
      form10: { duration: 0 },
      formData: { modalSelected: 0 },
      pointOptions: [],
      point1Options: [],
      point2Options: [],
      point3Options: [],
      typeOptions: [],
      targetOptions: [],
      dataTypeOpitons: [
        { id: 1, name: "静态效应" },
        { id: 2, name: "动态效应" },
        { id: 3, name: "振动响应" }
      ],
      computeOptions: [
        { id: 1, name: "挠度修正" },
        { id: 2, name: "应变花(45°)" }
      ],
      preMethodOptions: [
        { id: 1, name: "previous" },
        { id: 2, name: "next" },
        { id: 3, name: "nearest" },
        { id: 4, name: "linear" },
        { id: 5, name: "spline" },
        { id: 6, name: "pchip" },
        { id: 7, name: "makima" },
        { id: 8, name: "movmean" },
        { id: 9, name: "movmedian" }
      ],
      methodOptions: [
        { id: 1, name: "矩形窗" },
        { id: 2, name: "低通滤波" },
        { id: 3, name: "陷波滤波" }
      ],
      timeOptions: [
        { id: 0, name: "其他" },
        { id: 1, name: "1分钟" },
        { id: 2, name: "5分钟" },
        { id: 3, name: "10分钟" },
        { id: 4, name: "15分钟" },
        { id: 5, name: "20分钟" },
        { id: 6, name: "0.5小时" },
        { id: 7, name: "1小时" },
        { id: 8, name: "1.5小时" },
        { id: 99, name: "不分析" }
      ],
      timeOptions2: [
        { id: 1, name: "3小时" },
        { id: 2, name: "6小时" },
        { id: 3, name: "12小时" },
        { id: 99, name: "不分析" }
      ],
      timeOptions3: [
        { id: 0, name: "不分析" },
        { id: 1, name: "多项式" },
        { id: 2, name: "指数型" }
      ],
      durationOptions: [
        { id: 0, name: "不预警" },
        { id: 1, name: "预警" }
      ],
      rule1: {
        code: [{ required: true, message: "请输入测点编号", trigger: "blur" }],
        staticDynamicSelected: [{ required: true, message: "请选择数据类型", trigger: "change" }],
        site: [{ required: true, message: "请输入位置描述", trigger: "blur" }],
        frequency: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        model: [{ required: true, message: "请输入传感器型号", trigger: "blur" }],
        coefficient: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        unit: [{ required: true, message: "请输入单位", trigger: "blur" }],
        precisions: [{ required: true, message: "请输入", trigger: "blur" }],
        serialNumber: [{ validator: checkedSerialNumber, trigger: "blur" }]
        // selectedRel: [{ required: true, message: "请选择自选状态", trigger: "change" }]
      },
      ruleDerive: {
        firstTargetId: [{ required: true, message: "请选择测点", trigger: "change" }]
      },
      rule2: {
        patchDuration: [{ validator: checkedNumber, trigger: "blur" }]
      },
      rule3: {
        outlierDuration: [{ validator: checkedNumber, trigger: "blur" }]
      },
      rule4: {
        selectedArr: [{ required: true, type: "array", message: "请选择处理方法", trigger: "change" }],
        denoisingBoxWidth: [{ min: 1, type: "number", message: "窗宽度必须大于0", trigger: "blur" }],
        denoisingLowpassThreshold: [{ validator: checkedNumber, trigger: "blur" }],
        denoisingNotchThreshold: [{ validator: checkedNumber, trigger: "blur" }],
        denoisingDuration: [{ validator: checkedNumber, trigger: "blur" }]
      },
      rule5: {
        baselineRatio: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        baselineCutoffFrequency: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        baselineDuration: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        baselinePeriod: [
          { required: true, message: "时间间隔不能为空" },
          { type: "number", message: "时间间隔必须为数字值" }
        ],
        baselineType: [
          {
            required: true,
            message: "请选择类别参数",
            trigger: ["blur", "change"]
          }
        ]
      },
      rule6: {
        staticDynamicDuration: [{ validator: checkedNumber, trigger: "blur" }],
        staticDynamicCutoffFrequency: [{ validator: checkedNumber, trigger: "blur" }]
      },
      rule7: {
        timePeriod: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        timeDuration: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ]
      },
      rule8: {
        timePeriod: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        frequencyDurationOther: [{ validator: checkedNumber, trigger: "blur" }],
        tempEffectAnalyseDuration: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ],
        tempEffectIsolation: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        tempEffectInterval: [
          { required: true, message: "请输入", trigger: "blur" },
          { type: "number", message: "必须为数字" }
        ]
      },
      rule9: {
        timePeriod: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        frequencyDurationOther: [{ validator: checkedNumber, trigger: "blur" }],
        tempEffectAnalyseDuration: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        tempEffectIsolation: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        tempEffectInterval: [{ required: true, validator: checkedNumber, trigger: "blur" }]
      },
      rule10: {
        clower: [{ required: true, validator: checkedForwardLowerLimit, trigger: "blur" }],
        cupper: [{ validator: checkedForwardYellowUpperLimit, trigger: "blur" }],
        bupper: [{ validator: checkedForwardOrangeUpperLimit, trigger: "blur" }],
        flower: [{ required: true, validator: checkedNegativeYellowLowerLimit, trigger: "blur" }],
        fupper: [{ validator: checkedNegativeYellowUpperLimit, trigger: "blur" }],
        elower: [{ required: true, validator: checkedNegativeOrangeLowerLimit, trigger: "blur" }]
      },
      rule11: {
        modalTimeLag: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        modalNMin: [{ validator: checkedMinNumb, trigger: "blur" }],
        modalNMax: [{ validator: checkedMaxNumb, trigger: "blur" }]
      },
      trendRule: {
        trendOrder: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 1, type: "number", message: "阶数为1～4", trigger: "blur" },
          { max: 4, type: "number", message: "阶数为1～4", trigger: "blur" }
        ],
        trendDuration: [{ required: true, validator: checkedTrendDuration, trigger: "blur" }],
        trendPeriod: [{ required: true, validator: checkedNumber, trigger: "blur" }],
        trendNPoint: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 1, type: "number", message: "请输入大于0的整数", trigger: "blur" }
        ],
        trendInterval: [{ required: true, validator: checkedNumber, trigger: "blur" }]
      }
    };
  },

  mounted() {
    this.targetId = this.$route.params.id;
    this.currentPage = this.$route.query.currentPage;

    this.status = Number(this.$route.query.status) || "";

    this.searchTxt = this.$route.query.searchTxt || "";
    this.typeValue = Number(this.$route.query.typeValue) || "";

    console.log("currentPage", this.currentPage, "targetId", this.targetId, "status", this.status, "searchTxt", this.searchTxt, "typeValue", this.typeValue);

    // this.arr = JSON.parse(this.$route.params.arr);
    this.arr = JSON.parse(sessionStorage.getItem("multipleSelection"));
    this.projectId = Number(this.$store.state.projectId);

    console.log("传送过来的数据id", this.targetId, "arr", this.arr);

    let arrSet = this.$store.state.breadcrumbList;
    arrSet.push({ name: "设置" });
    this.$store.commit("getBreadcrumb", arrSet);

    // var projectId = sessionStorage.getItem('projectId');
    // if(projectId){
    //   this.projectId = projectId;
    // }

    // 读取历史
    this.isFirstEdit = 1;
    this.getForm1Data();

    // if (this.$route.query.type != "edit") {
    //   this.getPointTypes()
    // } else {
    //   this.isFirstEdit = 1
    //   this.getForm1Data()
    // }
    // 获取效应预测时间
    this.getEffectionTime();
    // 获取效应预测信息
    this.getEffectionInfo();
  },
  beforeDestroy() {
    let arrSet = this.$store.state.breadcrumbList;
    arrSet.splice(2);
    this.$store.commit("getBreadcrumb", arrSet);
  },
  methods: {
    // 格式化状态
    formatStatus(status) {
      if (status == "0") {
        return "无模型";
      }
      if (status == "1") {
        return "训练中";
      }
      if (status == "2") {
        return "校验失败";
      }
      if (status == "3") {
        return "训练完";
      }
    },
    // 获取效应预测默认时间
    getEffectionTime() {
      let end = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.$set(this.effectionForm, "endTime", end);
      let start = new Date().getTime() - 30 * 24 * 60 * 60 * 1000; // 向前推一个月
      this.$set(this.effectionForm, "beginTime", moment(new Date(start)).format("YYYY-MM-DD HH:mm:ss"));
    },
    // YYYY-MM-DD hh:mm:ss'转时间戳
    formDate(time) {
      const thisTime = time.replace(/ /g, "/");
      var nowTime = new Date(thisTime);
      nowTime = nowTime.getTime();
      return nowTime;
    },
    // 选择结束时间，开始时间自动向前推进一个月
    handleEndTimeChange() {
      let time = this.formDate(this.effectionForm.endTime);
      this.$set(this.effectionForm, "beginTime", moment(time - 30 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss"));
    },
    // 获取效应预测相关信息
    getEffectionInfo() {
      this.$axios.get(`${this.baseURL}data_management/select/${this.targetId}`).then((res) => {
        console.log("点击训练模型返回的数据", res.data.data.baseTargetForecastVO);
        let detail = res.data.data.baseTargetForecastVO;
        if (res.data.errCode == 200) {
          // 是否启动
          if (detail.startFlag != null) {
            this.$set(this.effectionForm, "startFlag", String(detail.startFlag));
          }
          // 时间
          if (detail.beginTime) {
            this.$set(this.effectionForm, "beginTime", detail.beginTime);
          }
          if (detail.endTime) {
            this.$set(this.effectionForm, "endTime", detail.endTime);
          }
          console.log("状态kkkkkkkkkkkkkkkkk", detail.status);
          if (detail.status != null) {
            this.$set(this.effectionForm, "status", detail.status);
          }
        }
      });
    },
    getComputeId() {
      this.$forceUpdate();
    },
    // 获取监测类型
    getPointTypes() {
      this.$axios.get(`${this.baseURL}base/baseTargetTypeGroup/`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.typeOptions = res.data;
          this.form1.typeGroupId = this.form1.typeGroupId ? this.form1.typeGroupId : res.data[0].id;
          this.getTargetOptions();
        }
      });
    },
    // 获取监测指标
    getTargetOptions() {
      this.$axios.get(`${this.baseURL}base/baseTargetType/byGroupId?typeGroupId=${this.form1.typeGroupId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.targetOptions = res.data;
          // 只要不是第一次进入编辑页面（isFirstEdit为0），都需要附默认值
          if (this.isFirstEdit == 0) {
            this.form1.typeId = res.data[0].id;
          }
          if (this.isFirstEdit == 1) this.isFirstEdit = 0;
          this.getPoints();
        }
      });
    },
    // 获取关联测点
    getPoints() {
      this.$axios.get(`${this.baseURL}base/baseTarget/type/${this.projectId}/${this.form1.typeId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.pointOptions = res.data;
          this.point1Options = this.initOptions();
          this.point2Options = this.initOptions();
          this.point3Options = this.initOptions();
        }
      });
    },
    initOptions() {
      return JSON.parse(JSON.stringify(this.pointOptions));
    },
    selectDataType(id) {
      // 数据类型为非静态时，1：清空时域统计数据，2：数据预处理设置模块的‘是否去处异常值’只能选否
      if (id != 1) {
        this.form7 = {};
        this.form2.outlierSelected = 0;
        this.form9.frequencyDuration = 0;
      } else {
        this.form9.frequencyDuration = 99;
        this.form9.frequencyWindow = this.form9.frequencySmooth = "";
      }
    },
    // 切换衍生指标
    selectDeriveTarget(id) {
      if (id == 0) {
        this.form1.equation = this.form1.firstTargetId = this.form1.secondTargetId = this.form1.thirdTargetId = "";
      } else {
        this.deriveForm.equation = 1;
      }
    },
    selectPoint(id, tag) {
      if (tag == "one") {
        this.point2Options = this.initOptions();
        this.point3Options = this.initOptions();
        //禁用第二、三个测点里边第一个测点选中的选项
        this.point2Options.forEach((item, i) => {
          if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
        this.point3Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
      } else if (tag == "two") {
        this.point1Options = this.initOptions();
        this.point3Options = this.initOptions();
        this.point1Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
        this.point3Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
      } else if (tag == "three") {
        this.point1Options = this.initOptions();
        this.point2Options = this.initOptions();
        this.point1Options.forEach((item, i) => {
          if (item.id == this.form1.secondTargetId) item.disabled = true;
          else if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
        this.point2Options.forEach((item, i) => {
          if (item.id == this.form1.thirdTargetId) item.disabled = true;
          else if (item.id == this.form1.firstTargetId) item.disabled = true;
          else if (item.id == id) item.disabled = true;
        });
      }
    },
    selectPlolish(id) {
      this.patchMethod = id == 0 ? "" : 1;
      this.form2.patchMethod = this.patchMethod;
    },
    selectPatchMethod(id) {
      this.form2.patchMethod = id;
    },
    selectWipeOff(id) {
      if (id == 0) this.form3.outlierDuration = "";
    },
    selectDenosing(id) {
      this.form4.denoisingSelected = id;
      if (id == 0) {
        this.form4 = {
          selectedArr: [],
          denoisingSelected: 0,
          denoisingDuration: "",
          denoisingBoxSelected: 0,
          denoisingNotchSelected: 0,
          denoisingNotchThreshold: "",
          denoisingLowpassSelected: 0,
          denoisingLowpassThreshold: ""
        };
      }
    },
    selectMethods(id) {
      if (id == 1) {
        this.form4.denoisingBoxSelected = this.form4.selectedArr.indexOf(id) > -1 ? 1 : 0;
        if (!this.form4.denoisingBoxSelected) this.form4.denoisingBoxWidth = "";
      }
      if (id == 2) {
        this.form4.denoisingLowpassSelected = this.form4.selectedArr.indexOf(id) > -1 ? 1 : 0;
        if (!this.form4.denoisingLowpassSelected) this.form4.denoisingLowpassThreshold = "";
      }
      if (id == 3) {
        this.form4.denoisingNotchSelected = this.form4.selectedArr.indexOf(id) > -1 ? 1 : 0;
        if (!this.form4.denoisingNotchSelected) this.form4.denoisingNotchThreshold = "";
      }
    },
    selectTimeFrame(id, tag) {
      if (id != 9 && tag == "form9") this.form9.frequencyDurationOther = "";
    },
    getForwardData() {
      this.form10.blower = this.form10.cupper;
      this.form10.alower = this.form10.bupper;
    },
    getNegativeData() {
      this.form10.eupper = this.form10.flower;
      this.form10.dupper = this.form10.elower;
    },
    ConvertTypes(val) {
      if (val) return Number(val);
    },
    async handleSave() {
      let checkedAll = 0, //已经通过校验的个数
        num = 0; //需要验证的个数
      await this.$refs["form1"].validate((valid) => {
        num += 1;
        if (valid) {
          checkedAll += 1;
          this.form1.coefficient = this.ConvertTypes(this.form1.coefficient);
        }
      });
      // if (this.form1.derive == 1) {
      //   await this.$refs["deriveForm"].validate((valid) => {
      //     num += 1;
      //     if (valid) checkedAll += 1;
      //   });
      // }
      // if (this.form2.patchSelected == 1) {
      //   await this.$refs["form2"].validate((valid) => {
      //     num += 1;
      //     if (valid) {
      //       checkedAll += 1;
      //       this.form2.patchDuration = this.ConvertTypes(this.form2.patchDuration);
      //     }
      //   });
      // }
      if (this.form3.outlierSelected == 1) {
        await this.$refs["form3"].validate((valid) => {
          num += 1;
          if (valid) {
            checkedAll += 1;
            this.form3.outlierDuration = this.ConvertTypes(this.form3.outlierDuration);
          }
        });
      }
      if (this.form4.denoisingSelected == 1) {
        await this.$refs["form4"].validate((valid) => {
          num += 1;
          if (valid) {
            checkedAll += 1;
            this.form4.denoisingBoxWidth = this.ConvertTypes(this.form4.denoisingBoxWidth);
            this.form4.denoisingNotchThreshold = this.ConvertTypes(this.form4.denoisingNotchThreshold);
            this.form4.denoisingDuration = this.ConvertTypes(this.form4.denoisingDuration);
            this.form4.denoisingLowpassThreshold = this.ConvertTypes(this.form4.denoisingLowpassThreshold);
          }
        });
      }
      if (this.form5.baselineSelected == 1) {
        await this.$refs["form5"].validate((valid) => {
          num += 1;
          if (valid) {
            checkedAll += 1;
            this.form5.baselinePeriod = this.ConvertTypes(this.form5.baselinePeriod);
            this.form5.baselineDuration = this.ConvertTypes(this.form5.baselineDuration);
            this.form5.baselineRatio = this.ConvertTypes(this.form5.baselineRatio);
            this.form5.baselineCutoffFrequency = this.ConvertTypes(this.form5.baselineCutoffFrequency);
          }
        });
      }
      if (this.form6.staticDynamicSeparationSelected == 1) {
        await this.$refs["form6"].validate((valid) => {
          num += 1;
          if (valid) {
            checkedAll += 1;
            this.form6.staticDynamicDuration = this.ConvertTypes(this.form6.staticDynamicDuration);
            this.form6.staticDynamicCutoffFrequency = this.ConvertTypes(this.form6.staticDynamicCutoffFrequency);
            this.form6.staticDynamicCutoffFrequency = this.ConvertTypes(this.form6.staticDynamicCutoffFrequency);
            this.form6.staticDynamicCutoffFrequency = this.ConvertTypes(this.form6.staticDynamicCutoffFrequency);
            this.form6.staticDynamicCutoffFrequency = this.ConvertTypes(this.form6.staticDynamicCutoffFrequency);
          }
        });
      }
      if (this.form1.staticDynamicSelected != 1) {
        await this.$refs["formData"].validate((valid) => {
          num += 1;
          if (valid) checkedAll += 1;
        });
        await this.$refs["form9"].validate((valid) => {
          num += 1;
          if (valid) {
            checkedAll += 1;
            this.form9.frequencyDurationOther = this.ConvertTypes(this.form9.frequencyDurationOther);
          }
        });
      }
      await this.$refs["form7"].validate((valid) => {
        num += 1;
        if (valid) {
          checkedAll += 1;
          this.form7.timePeriod = this.ConvertTypes(this.form7.timePeriod);
          this.form7.timeDuration = this.ConvertTypes(this.form7.timeDuration);
        }
      });
      await this.$refs["form8"].validate((valid) => {
        num += 1;
        if (valid) {
          checkedAll += 1;
          this.form8.tempEffectAnalyseDuration = this.ConvertTypes(this.form8.tempEffectAnalyseDuration);
        }
      });
      //   if(this.form10.duration==1) {
      //     await this.$refs["form10"].validate((valid) => {
      //       num+=1
      //       if(valid) checkedAll+=1
      //     });
      //   }
      if (this.trendForm.trendType != 0) {
        await this.$refs["trendForm"].validate((valid) => {
          num += 1;
          if (valid) checkedAll += 1;
        });
      }
      if (num != checkedAll) return;
      let form = { projectId: this.projectId };
      Object.assign(
        form,
        // this.form1,   //基础信息
        this.form2,
        this.form3,
        this.form4,
        this.form5,
        this.form6,
        this.form7,
        this.form8,
        this.form9,
        // this.form10,   //预警
        this.formData,
        this.deriveForm,
        this.trendForm,
        this.effectionForm // 效应预测
      );
      delete form.targetId;
      delete form.modalDuration;

      if (form.baselinePeriod != "") {
        if (this.form9.frequencyDuration != 99) {
          form.frequencyPeriod = form.baselinePeriod;
        }
        if (this.form6.staticDynamicSeparationSelected == 1) {
          form.staticDynamicPeriod = form.baselinePeriod; //动静分离时间间隔
        }
        if (this.form8.tempEffectDuration != 99) {
          form.tempEffectPeriod = form.baselinePeriod; // 温度的延迟时间
        }
        form.timePeriod = form.baselinePeriod;
        // tempEffectPeriod
      }
      console.log("form提交", form);
      // return;
      if (this.arr.length) {
        let arr1 = [];
        this.arr.forEach((item) => {
          arr1.push(item.target.id);
        });

        form["targetIds"] = arr1.join(",");
        console.log("form", form);
        this.$axios.post(`${this.baseURL}data_management/save/batch`, this.$qs.stringify(form)).then((res) => {
          console.log("res-保存1", res);

          res = res.data;
          if (res.errCode == 200) this.$router.push({ path: "/settings", query: { currentPage: this.currentPage, status: this.status, searchTxt: this.searchTxt, typeValue: this.typeValue } });
        });
        return;
      }
      if (this.targetId) {
        //   data_management/save/
        // this.$axios.put(`${this.baseURL}base/baseTarget/${this.targetId}`,
        this.$axios.post(`${this.baseURL}data_management/save/${this.targetId}`, this.$qs.stringify(form)).then((res) => {
          console.log("res-保存1", res);

          res = res.data;
          if (res.errCode == 200) this.$router.push({ path: "/settings", query: { currentPage: this.currentPage, status: this.status, searchTxt: this.searchTxt, typeValue: this.typeValue } });
        });
      } else {
        // this.$axios.post(`${this.baseURL}base/baseTarget/`, this.$qs.stringify(form)).then((res) =>{
        this.$axios.post(`${this.baseURL}data_management/save/`, this.$qs.stringify(form)).then((res) => {
          console.log("res-保存2", res);
          res = res.data;
          if (res.errCode == 200) this.$router.push({ path: "/settings", query: { currentPage: this.currentPage, status: this.status, searchTxt: this.searchTxt, typeValue: this.typeValue } });
        });
      }
    },
    back() {
      // this.$router.push("/settings?currentPage=" + this.currentPage);
      this.$router.push({ path: "/settings", query: { currentPage: this.currentPage, status: this.status, searchTxt: this.searchTxt, typeValue: this.typeValue } });
    },
    // 编辑前获取数据
    getForm1Data() {
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.form1 = res.data;
          this.getPointTypes();
          // 衍生指标选是
          if (this.form1.derive == 1) this.getForm2Data();
        }
      });
      // 获取数据预处理设置、数据二次处理
      this.$axios.get(`${this.baseURL}setttings/settingsDataClean/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          let obj2 = {};
          obj2.patchSelected = res.data.patchSelected ? res.data.patchSelected : 0;
          obj2.patchMethod = res.data.patchMethod;
          obj2.patchDuration = res.data.patchDuration ? res.data.patchDuration : "";
          this.form2 = obj2;
          let obj3 = {};
          obj3.outlierSelected = res.data.outlierSelected ? res.data.outlierSelected : 0;
          obj3.outlierDuration = res.data.outlierDuration ? res.data.outlierDuration : "";
          this.form3 = obj3;

          let obj5 = {};
          obj5.baselineSelected = res.data.baselineSelected ? res.data.baselineSelected : 0;
          obj5.baselineType = res.data.baselineType ? res.data.baselineType : 1;
          obj5.baselinePeriod = res.data.baselinePeriod ? res.data.baselinePeriod : "";

          obj5.baselineDuration = res.data.baselineDuration ? res.data.baselineDuration : "";
          obj5.baselineRatio = res.data.baselineRatio ? res.data.baselineRatio : "";
          obj5.baselineCutoffFrequency = res.data.baselineCutoffFrequency ? res.data.baselineCutoffFrequency : "";
          this.form5 = obj5;

          let obj6 = {};
          obj6.staticDynamicSeparationSelected = res.data.staticDynamicSeparationSelected ? res.data.staticDynamicSeparationSelected : 0;
          obj6.staticDynamicDuration = res.data.staticDynamicDuration ? res.data.staticDynamicDuration : "";
          obj6.staticDynamicCutoffFrequency = res.data.staticDynamicCutoffFrequency ? res.data.staticDynamicCutoffFrequency : "";
          this.form6 = obj6;
          let obj4 = {};
          obj4.denoisingBoxSelected = res.data.denoisingBoxSelected;
          obj4.denoisingNotchSelected = res.data.denoisingNotchSelected;
          obj4.denoisingLowpassSelected = res.data.denoisingLowpassSelected;
          obj4.denoisingSelected = res.data.denoisingSelected == 1 ? res.data.denoisingSelected : 0;
          obj4.denoisingBoxWidth = res.data.denoisingBoxWidth ? res.data.denoisingBoxWidth : "";
          obj4.denoisingLowpassThreshold = res.data.denoisingLowpassThreshold ? res.data.denoisingLowpassThreshold : "";
          obj4.denoisingNotchThreshold = res.data.denoisingNotchThreshold ? res.data.denoisingNotchThreshold : "";
          obj4.denoisingDuration = res.data.denoisingDuration ? res.data.denoisingDuration : "";
          obj4.selectedArr = [];
          this.form4 = obj4;
          res.data.denoisingBoxSelected == 1 ? this.form4.selectedArr.push(this.methodOptions[0].id) : "";
          res.data.denoisingLowpassSelected == 1 ? this.form4.selectedArr.push(this.methodOptions[1].id) : "";
          res.data.denoisingNotchSelected == 1 ? this.form4.selectedArr.push(this.methodOptions[2].id) : "";
        }
      });
      // 获取数据分析处理设置
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/option/${this.targetId}`).then((res) => {
        res = res.data;
        delete res.data.target;
        if (res.errCode == 200) {
          this.formData.modalSelected = res.data.modalSelected || 0;
          let obj7 = {};
          obj7.timePeriod = res.data.timePeriod ? res.data.timePeriod : 300;
          obj7.timeDuration = res.data.timeDuration ? res.data.timeDuration : 99;
          this.form7 = obj7;
          let obj8 = {};
          obj8.tempEffectDuration = res.data.tempEffectDuration || 99;
          obj8.tempEffectAnalyseDuration = res.data.tempEffectAnalyseDuration;
          obj8.tempEffectIsolation = res.data.tempEffectIsolation;
          obj8.tempEffectInterval = res.data.tempEffectInterval;
          this.form8 = obj8;
          let obj9 = {};
          obj9.frequencyDuration = res.data.frequencyDuration ? res.data.frequencyDuration : 99;
          obj9.frequencyDurationOther = res.data.frequencyDurationOther ? res.data.frequencyDurationOther : "";
          obj9.frequencyWindow = res.data.frequencyWindow ? res.data.frequencyWindow : "";
          obj9.frequencySmooth = res.data.frequencySmooth ? res.data.frequencySmooth : "";
          this.form9 = obj9;
          let trendObj = {};
          trendObj.trendType = res.data.trendType ? res.data.trendType : 0;
          trendObj.trendOrder = res.data.trendOrder ? res.data.trendOrder : "";
          trendObj.trendDuration = res.data.trendDuration ? res.data.trendDuration : "";
          trendObj.trendPeriod = res.data.trendPeriod ? res.data.trendPeriod : "";
          trendObj.trendNPoint = res.data.trendNPoint ? res.data.trendNPoint : "";
          trendObj.trendInterval = res.data.trendInterval ? res.data.trendInterval : "";
          this.trendForm = trendObj;
        }
      });
      // 获取单指标阈值处理设置
      this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.form10 = res.data;
      });
    },
    getForm2Data() {
      // 获取衍生指标
      this.$axios.get(`${this.baseURL}setttings/settingsDataDerivation/${this.targetId}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) this.deriveForm = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.time-div {
  width: 500px;
  display: flex;

  &::v-deep .el-input__inner {
    width: 200px !important;
  }

  span.line {
    width: 50px;
    text-align: center;
    margin-top: 13px;
  }
}

.exercise-model {
  position: absolute;
  top: 10px;
  right: 20px;
}

.derive,
.multi-form {
  &::v-deep .el-form-item__error {
    top: 38px;
  }
}

.trendForm::v-deep {
  .el-form-item {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 178px;
    }

    .el-form-item__label {
      width: 152px;
    }

    .multi-form .el-form {
      padding: 0 1% 0 6.47%;
    }

    .analyse .el-form {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 198px;
        }
      }

      .interval {
        margin-left: 100px;
      }

      .el-form-item__label {
        width: 100px;
      }
    }

    .rehandle {
      .el-form-item {
        margin-right: 2%;
      }

      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 70px;
        }
      }
    }

    .limit-value {
      .el-form-item {
        width: 258px;

        .el-input,
        .el-input__inner {
          width: 130px;
        }
      }
    }
  }
}

@media screen and (max-width: 1750px) {
  .set-point::v-deep {
    .el-input,
    .el-input__inner {
      width: 127px;
    }

    .el-form:first-child {
      .el-form-item__label {
        min-width: 122px;
        white-space: nowrap;
      }
    }

    .derive {
      .el-form-item__label {
        min-width: 110px;
        white-space: nowrap;
      }

      .el-input,
      .el-input__inner {
        width: 183px;
      }
    }

    .multi-form .el-form {
      padding-left: 20px;
    }

    .analyse section {
      .el-form-item:first-child {
        .el-form-item__content {
          width: 127px;
        }
      }

      .el-form-item:nth-child(2) {
        margin-left: 15px;
      }

      .interval {
        margin-left: 80px;
      }
    }

    .rehandle {
      .el-form-item:not(:first-child) {
        .el-form-item__label {
          width: 72px;
        }
      }

      .el-form-item {
        margin-right: 20px !important;
      }
    }

    .limit-value {
      .el-form-item {
        width: 220px;

        .el-form-item__label {
          margin-right: 10px !important;
        }

        .el-input,
        .el-input__inner {
          width: 115px;
        }
      }

      .el-form-item:nth-child(odd) {
        width: 220px;

        .el-form-item__label {
          width: 86px !important;
        }
      }
    }
  }
}

.time_length::v-deep .el-form-item__label {
  margin-left: 40px;
}

.el-form::v-deep {
  .el-form-item__label {
    padding: 0;
    height: 52px;
    line-height: 52px;
    text-align: center;
    background: #0c265a;
  }

  .red-warning .el-form-item__label {
    text-align: right;
  }

  .el-form-item__content {
    height: 52px;
    width: auto;
    line-height: 52px;
    text-align: center;
  }

  .el-input__inner {
    padding: 0;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  .el-select {
    .el-input__inner {
      text-align: left;
      padding-left: 15px;
      border: 1px solid rgba(151, 151, 151, 0.29);
    }

    .el-input__icon {
      height: 30px;
      line-height: 30px;
    }
  }

  .el-input.is-disabled .el-input__inner {
    border: 1px solid #383e41 !important;
    background: rgba(56, 63, 65, 0.29) !important;
  }

  .el-form-item.long-input {
    width: 50% !important;

    .el-input,
    .el-input__inner {
      // width: 95% !important;
    }
  }
}

p {
  color: #ffffff;
  line-height: 40px;
  text-align: center;
}

.el-form-item {
  margin: 0;
  border-top: 1px solid #2c3134;
}

.el-form-item {
  display: inline-flex;

  &::v-deep {
    .el-form-item__content {
      flex: 1;
    }
  }
}

.title {
  width: 152px;
  display: flex;
  word-wrap: none;
  white-space: nowrap;
  color: #fff;
  align-items: center;
  justify-content: center;
  background: #0c265a;
}

.max_factorial {
  margin-left: 20px;
}

.denosing-form .title {
  border-bottom: 1px solid #2c3134;
}

.derive {
  &::v-deep {
    .el-form-item__label,
    .el-col {
      margin-right: 22px;
    }
  }
}

.multi-form,
.el-form:first-of-type,
.el-form:nth-of-type(2) {
  margin: 4px;
  border-radius: 8px;
  margin-bottom: 26px;
  border: 1px solid #2c3134;
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.el-form:first-child {
  &::v-deep {
    .el-form-item__content > div:not(.el-select) {
      .el-input__inner {
        padding: 0;
        border: none;
      }
    }
  }

  .el-form-item {
    width: 50%; //25
  }

  .el-form-item:nth-of-type(5) {
    //width: 50%;
  }

  .el-form-item:nth-of-type(6) {
    //width: 50%;
    //padding-right: 42px;
  }

  .el-form-item:nth-last-of-type(3) {
    width: 50%;
  }
}

.el-form:nth-child(2) {
  .el-form-item {
    display: block;

    &::v-deep {
      .el-form-item__content {
        display: inline-flex;
      }
    }

    .el-select:not(:last-child) {
      margin-right: 22px;
    }
  }
}

.multi-form {
  section {
    display: flex;
    border-top: 1px solid #2c3134;

    p {
      width: 152px;
      height: 52px;
      line-height: 52px;
      background: #0c265a;
    }

    .el-form {
      flex: 1;
      margin: 0;
      border: none;
      display: flex;
      flex-wrap: wrap;
      box-shadow: none;

      .el-form-item {
        border: none;
        height: 52px;

        &::v-deep {
          .el-form-item__label {
            text-align: left;
            margin-right: 20px;
            white-space: nowrap;
            background: transparent;
          }
        }

        .unit {
          padding: 0 12px;
          white-space: nowrap;
        }
      }
    }
  }

  .denosing-form {
    .el-form {
      margin: 0;
      border-radius: 0;
      border-bottom: 1px solid #2c3134;
    }

    .el-form-item:nth-of-type(2) {
      width: 50%;

      &::v-deep {
        .el-form-item__content {
          white-space: nowrap;
        }
      }
    }

    .el-form-item {
      &::v-deep .el-form-item__error {
        top: 75% !important;
      }
    }
  }

  .rehandle {
    .el-form-item:first-child {
      &::v-deep .el-form-item__label {
        width: 182px;
      }
    }
  }
}

.multi-form:nth-of-type(-n + 1) {
  .el-form-item {
    margin-right: 4%;
  }
}

.set-time {
  padding: 0 2% !important;

  .el-form-item:first-child {
    margin-right: 20px;
  }
}

.set-threshold {
  .title {
    border: none;
  }

  .form-title {
    margin: 20px 0;

    span {
      width: 50%;
      display: inline-block;
    }
  }

  .form-list {
    flex: 1;
    padding: 0 0 23px 2%;

    .el-form {
      padding: 0;

      &::v-deep {
        .el-form-item__content {
          width: 78px;
        }

        .el-form-item__label {
          padding: 0;
          width: 90px;
        }
      }
    }

    .limit-value {
      width: 50%;

      .el-form-item {
        display: inline-flex;
      }
    }
  }
}

.btns {
  margin-top: 44px;
  margin-bottom: 40px;
  text-align: center;

  .el-button {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  }

  .el-button:last-child {
    margin-left: 50px;
    background: #1e2529;
  }
}
</style>
